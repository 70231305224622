import React from 'react';

export default () => (
  <div className="container">
    <div className="affiliate-info">
      <div className="affiliate-info__content">
        <h3>Who can be a ruttl partner?</h3>
        <p>
          Yet the answer remains simple : anyone who can help us spread the word
          out there. We’d be happy to help you enjoy a lifetime of earnings with
          ruttl.
        </p>
      </div>
      <img src="/assets/img/html.png" alt="" />
    </div>
  </div>
);
