import React, { useState, useEffect, /* useMemo, */ useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

/* const privilegePlans = [
  {
    id: 'pro',
    amount: {
      original: 600,
      twentyPercent: 480,
      blackFriday: 150,
      referral: 144,
    },
  },
  {
    id: 'team-5',
    amount: {
      original: 1200,
      twentyPercent: 960,
      blackFriday: 300,
      referral: 288,
    },
  },
  {
    id: 'team-20',
    amount: {
      original: 2000,
      twentyPercent: 1600,
      blackFriday: 500,
      referral: 480,
    },
  },
]; */

const AffiliateDetails = ({ id }) => {
  const [data, setData] = useState(null);

  /* const totalRevenue = useMemo(() => {
    if (data)
      return data.users.reduce(
        (total, user) =>
          total +
          (privilegePlans.find((p) => p.id === user.plan)?.amount.referral ||
            0),
        0,
      );

    return 0;
  }, [data]); */

  const copyToClipboard = useCallback((text) => () => {
    navigator.clipboard.writeText(text);
  });

  useEffect(() => {
    if (data === null && id) {
      axios
        .get(
          `https://us-central1-ruttlp.cloudfunctions.net/affiliates?id=${id}`,
        )
        .then((res) => {
          setData(res.data);
        })
        .catch(() => setData(0));
    }
  }, [id, data]);

  return (
    <section className="affiliate-details">
      <div className="container">
        {data === null && (
          <div style={{ margin: '50px 0' }}>Getting affiliate data...</div>
        )}
        {data === 0 && (
          <div style={{ margin: '50px 0' }}>Error: Invalid affiliate ID</div>
        )}
        {data?.code && (
          <>
            <h1>Affiliate Admin Portal</h1>
            <div className="affiliate-details__overview">
              <h3>Overview</h3>
              <p>
                <span>Referral code: </span>
                <span className="affiliate-details__overview--code">
                  {data.code}
                </span>
                <button
                  type="button"
                  className="button-unstyled affiliate-details__overview--copy-code"
                  onClick={copyToClipboard(data.code)}>
                  <img src="/assets/img/copy.svg" alt="copy" width="12px" />
                </button>
              </p>
              <p>
                <span>Email: </span>
                <span>{data.email}</span>
              </p>
              <p>
                <span>Total referred users: </span>
                <strong>{data.users.length}</strong>
              </p>
              {/* <p>
                <span>Total revenue generated: </span>
                <strong>{`$${totalRevenue}`}</strong>
              </p> */}
              {/* <p style={{ lineHeight: '1.4' }}>
                <small style={{ fontSize: 11 }}>
                  <i>
                    Disclaimer: The total revenue displayed above is the total
                    business earned by ruttl. The amount of your payout will
                    depend on the pre-decided percentage of commission.
                  </i>
                </small>
              </p> */}
            </div>
            <h3 style={{ margin: '30px 0 0' }}>Promotional content</h3>
            <p style={{ margin: '0 0 20px' }}>
              You might want to&nbsp;
              <a
                href="https://paper.dropbox.com/doc/Promotional-Content-for-Affiliates--BW4WGbs6eFLjAFVlpD0FKOtMAg-K4dYBMHHtydgXgsL1N5s5"
                target="_blank"
                rel="noopener noreferrer">
                use this content
              </a>
              &nbsp;while promoting to your users.
            </p>
            {data.users.length > 0 && (
              <>
                <h3 style={{ margin: '30px 0 15px' }}>Referred users</h3>
                <div className="affiliate-details__table">
                  <table>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Email address</th>
                      <th>Plan</th>
                      <th>Amount</th>
                    </tr>
                    {data.users
                      .sort(
                        (a, b) => b.createdAt._seconds - a.createdAt._seconds,
                      )
                      .map((user, i) => (
                        <tr key={user.createdAt._seconds}>
                          <td>{i + 1}</td>
                          <td>
                            {`${new Date(
                              user.createdAt._seconds * 1000,
                            ).toDateString()}, ${new Date(
                              user.createdAt._seconds * 1000,
                            ).toLocaleTimeString()}`}
                          </td>
                          <td>{user.email}</td>
                          <td>{user.plan}</td>
                          <td>{user.amount}</td>
                          {/* <td>
                            {`$${
                              privilegePlans.find((p) => p.id === user.plan)
                                ?.amount.referral || 0
                            }`}
                          </td> */}
                        </tr>
                      ))}
                  </table>
                </div>
                <p style={{ lineHeight: '1.4' }}>
                  <small style={{ fontSize: 11 }}>
                    <i>
                      Disclaimer: The revenue displayed above is the business
                      earned by ruttl. The amount of your payout will depend on
                      the pre-decided percentage of commission.
                    </i>
                  </small>
                </p>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

AffiliateDetails.propTypes = {
  id: PropTypes.string,
};

export default AffiliateDetails;
