import React from 'react';

export default () => (
  <div className="affiliate-offer">
    <div className="container">
      <h3>What we offer</h3>
      <div className="affiliate-offer__cards">
        <div className="affiliate-offer__cards--card">
          <div style={{ minHeight: 92 }}>
            <img
              src="/assets/img/commission-30.svg
          "
              alt="commission"
            />
          </div>
          <div>
            <h4>25% commission</h4>
            <p>
              Earn 25% for every paying customer you refer, for as long as they
              stay a paying customer.
            </p>
          </div>
        </div>
        <div className="affiliate-offer__cards--card">
          <div style={{ minHeight: 92 }}>
            <img src="/assets/img/referral-25.svg" alt="referral" />
          </div>
          <div>
            <h4>30% discount</h4>
            <p>
              When a customer comes through your code, they get a 30% discount
              on any ruttl plan!
            </p>
          </div>
        </div>
        <div className="affiliate-offer__cards--card">
          <div style={{ minHeight: 92 }}>
            <img src="/assets/img/fast-payouts.svg" alt="fast payouts" />
          </div>
          <div>
            <h4>Fast payout</h4>
            <p>Payouts are on the 1st of each month.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
