import React from 'react';
import PropTypes from 'prop-types';

const AffiliateHero = ({ toggleFormModal }) => (
  <div className="container">
    <div className="affiliate-hero">
      <div className="affiliate-hero__content">
        <h1>Recommend Ruttl &amp; get paid 💰</h1>
        <p>
          Share Ruttl with your audience and earn a recurring
          <span className="bold">&nbsp;25% commission&nbsp;</span>
          for every paying customer you refer :)
        </p>
        <button type="button" className="button" onClick={toggleFormModal}>
          Become an affiliate partner
        </button>
      </div>
      <div className="affiliate-hero__image">
        <img src="/assets/img/affiliate-bg.png" alt="" />
      </div>
    </div>
  </div>
);

AffiliateHero.propTypes = {
  toggleFormModal: PropTypes.func,
  // showBottomPopup: PropTypes.bool,
};
export default AffiliateHero;
