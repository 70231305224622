/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Range, getTrackBackground } from 'react-range';
import PropTypes from 'prop-types';

const AffiliateEarnings = ({ toggleFormModal }) => {
  const [values, setValues] = useState([15]);

  const trackBg = getTrackBackground({
    values,
    min: 0,
    max: 30,
    colors: ['rgb(255,255,255)', 'rgba(255,255,255,0.2)'],
  });

  return (
    <div className="affiliate-earnings">
      <div className="container">
        <h2>How much can you earn 🤔</h2>
        <div className="slider">
          <Range
            step={5}
            min={0}
            max={30}
            values={values}
            onChange={(val) => setValues(val)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '8px',
                  width: '60%',
                  background: trackBg,
                  borderRadius: '20px',
                }}>
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  minWidth: 'fit-content',
                }}>
                <div className="tooltip">
                  <span className="text">
                    {values[0]} Referrals - ${values[0] * 600}
                  </span>
                  <span className="subtext">/yearly</span>
                </div>
                <div className="sliderBtn" />
              </div>
            )}
          />
        </div>
        <p>
          Estimations based on yearly commissions when your user(s) purchase(s)
          our annual&nbsp;
          <Link to="/pricing/">Team plan</Link>.
        </p>
        <button
          type="button"
          className="button button-white"
          onClick={toggleFormModal}>
          Become an affiliate partner
        </button>
      </div>
    </div>
  );
};

AffiliateEarnings.propTypes = {
  toggleFormModal: PropTypes.func,
  // showBottomPopup: PropTypes.bool,
};

export default AffiliateEarnings;
