import React from 'react';
import PropTypes from 'prop-types';

const AffiliateProcess = ({ toggleFormModal }) => (
  <div className="affiliate-process__bg">
    <div className="affiliate-process container">
      <div className="fixed">
        <h2>
          How it
          <span> works</span>
        </h2>
        <img src="/assets/img/affiliate.png" alt="" />
      </div>
      <div className="scrolling">
        <div className="section">
          <img className="icon" src="/assets/img/apply.svg" alt="" />
          <h3 className="sub-heading">Apply</h3>
          <p>
            Submit the request and fill the form. We shall approve your
            application from our end.
          </p>
          <button type="button" className="button" onClick={toggleFormModal}>
            Submit affiliate form
          </button>
        </div>
        <div className="section">
          <img className="icon" src="/assets/img/check-your-email.svg" alt="" />
          <h3 className="sub-heading">
            Check your email for unique dashboard link
          </h3>
          <p>
            Upon approval, our team will reach out to you via email with an
            invitation to your personal affiliate portal. This is where
            you&apos;ll find your unique, cookie-based URL that will look
            something like this:
          </p>
          <button type="button" className="button" onClick={toggleFormModal}>
            Submit affiliate form
          </button>
        </div>
        <div className="section">
          <img
            className="icon"
            src="/assets/img/unique-coupon-code.svg"
            alt=""
          />
          <h3 className="sub-heading">Share your unique coupon code</h3>
          <p>
            Copy your unique coupon code and share it within your network, like
            LinkedIn, Facebook, Twitter, any other community or closed group.
            Every user who pays using your coupon code will receive a flat 30%
            off on their purchase for the first 3 months, and your unique
            dashboard will add this user.
          </p>
          <button type="button" className="button" onClick={toggleFormModal}>
            Submit affiliate form
          </button>
        </div>
      </div>
    </div>
  </div>
);

AffiliateProcess.propTypes = {
  // showBottomPopup: PropTypes.bool,
  toggleFormModal: PropTypes.func,
};

export default AffiliateProcess;
