import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import Main from 'styles/affiliate';
import AffiliateDetails from '../components/Affiliate/AffiliateDetails';
import GetStarted from '../components/GetStarted/GetStarted';
// import Network from '../components/Affiliate/Network';
import Info from '../components/Affiliate/Info';
import AffiliateHero from '../components/Affiliate/AffiliateHero';
import AffiliateOffer from '../components/Affiliate/AffiliateOffer';
import AffiliateProcess from '../components/Affiliate/AffiliateProcess';
import AffiliateEarnings from '../components/Affiliate/AffiliateEarnings';
import Registration from '../components/Affiliate/Registration';
import 'styles/affiliate.scss';
import NewFaq from '../components/NewFaq/NewFaq';

const faqData = [
  {
    q: 'How much commission will I earn?',
    a: 'You will earn 25% commission on every user, who uses your unique coupon code.',
  },
  {
    q: 'How can I become an affiliate for ruttl?',
    a: 'Simply click on the ‘Submit a Request’ button above, fill in your details, and we will keep you posted on the next steps via email.',
  },
  {
    q: 'How will the users get affiliate discounts?',
    a: 'We will send you a unique code via email, which can be used by your members while upgrading their accounts. We shall track these unique codes & add them to your list in the dashboard.',
  },
  {
    q: 'How long is my unique code valid for?',
    a: 'Your unique code will be valid forever, unless we mutually decide to deactivate the same.',
  },
  {
    q: 'How do I access a list of all users, who have signed up using my unique coupon code?',
    a: 'All user related data & your earnings will be visible to you in the dashboard itself. We shall provide you with access to this dashboard via email, once you submit your details in the form above.',
  },
  {
    q: 'When do payouts usually happen?',
    a: 'Payouts are processed by us every 30 days.',
  },

  {
    q: 'What if a user with my coupon code deletes their account, or decides to unsubscribe?',
    a: 'You will get the commission even if the user has kept their paid account for a minimum of one month without deleting or unsubscribing from the plan..',
  },
];

const Affiliate = ({ location }) => {
  const [showFormModal, setFormModal] = useState(false);

  const toggleFormModal = useCallback(() => {
    setFormModal((state) => !state);
  }, []);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Affiliate | ruttl 🤝"
            description="Join ruttl's affiliate program and earn commission - best visual feedback and collaboration tool. Become a ruttl affiliate today!"
            url="https://ruttl.com/affiliate/"
          />
          <main className="affiliate-styled-main">
            {typeof window !== 'undefined' && (
              <>
                {new URL(window.location.href).searchParams.get('aff') ? (
                  <AffiliateDetails
                    id={new URL(window.location.href).searchParams.get('aff')}
                  />
                ) : (
                  <>
                    {/* <AdminLinkRequest /> */}
                    <AffiliateHero toggleFormModal={toggleFormModal} />
                    <AffiliateOffer />
                    <AffiliateProcess toggleFormModal={toggleFormModal} />
                    <AffiliateEarnings toggleFormModal={toggleFormModal} />
                    <Info />
                    {/* <Network /> */}
                    <NewFaq data={faqData} />
                    <GetStarted
                      toggleSignup={toggleSignup}
                      data={{
                        heading: 'Get started as a ruttl affiliate today',
                        button: 'Become an affiliate partner',
                      }}
                    />
                  </>
                )}
              </>
            )}
          </main>

          <Registration
            showFormModal={showFormModal}
            toggleFormModal={toggleFormModal}
          />
        </>
      )}
    </Layout>
  );
};

Affiliate.propTypes = {
  location: PropTypes.object,
};

export default Affiliate;
